<template>
    <section>
        <cargando v-if="cargando" />
        <div class="row mx-0 align-items-center">
            <div class="btn-back">
                <i class="icon-left f-12" @click="$router.back(-1)" />
            </div>
            <div class="col-auto text-general f-600 f-18">
                Actualización de datos
            </div>
        </div>
        <div class="row mx-0 mt-2 bg-white br-8 p-3">
            <button type="button" class="btn-grey px-3" @click="replaceFile">
                Reemplazar archivo
            </button>
            <div class="col-auto d-middle text-general">
                {{ fileName }}<i class="icon-ok-circled ml-2 text-green f-20" />
            </div>
            <div class="col-auto d-middle ml-auto">
                <i class="icon-ok-circled text-green f-20" />
                <i class="mx-1 text-general">
                    {{ existen }} registros
                </i>
            </div>
            <div class="col-auto d-middle">
                <i class="icon-cancel-circled-outline text-alert f-20" />
                <i class="mx-1 text-general">
                    {{ noExisten }} Errores
                </i>
            </div>
        </div>
        <div class="row mx-0 mt-2 bg-white br-8 p-3">
            <div class="col-auto ml-2 px-0 f-18 f-500 text-general">
                Datos de productos actualizar
            </div>
            <div class="col-auto ml-auto px-0 f-18 f-500 text-general">
                {{ tableData.length }} productos seleccionador
            </div>
            <div class="col-2">
                <el-select v-model="action" size="small" class="w-100" placeholder="Acciones" :clearable="true" @clear="action = null" @change="validAction">
                    <el-option
                    v-for="item in actions"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-2">
                <el-input
                v-model="buscar"
                placeholder="Buscar"
                size="small"
                prefix-icon="icon-search"
                autocomplete="nope"
                class="cabecera-busqueda mx-1 br-20"
                />
            </div>
        </div>
        <div class="row mx-0 mt-1">
            <div class="col-12">
                <el-table
                :data="tableData.filter(data => !buscar || data.nombre.toLowerCase().includes(buscar.toLowerCase()))"
                header-row-class-name="text-general f-15 f-600"
                style="width: 100%"
                >
                    <el-table-column
                    min-width="80"
                    align="center"
                    >
                        <template slot="header">
                            <el-checkbox class="check-red" @change="selectedAll($event)" />
                        </template>
                        <template slot-scope="scope">
                            <el-checkbox v-model="scope.row.active" class="check-red" @change="selectOnly($event, scope.row.id)" />
                        </template>
                    </el-table-column>
                    <el-table-column
                    width="55"
                    class-name="text-center"
                    >
                        <template slot-scope="scope">
                            <el-tooltip content="No existe en cedis" placement="bottom" effect="light">
                                <i v-if="_.isNull(scope.row.estado)" class="icon-alert-circled f-19" style="color:#E50019" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column
                    :label="type == 2 ? 'Barcode' : 'SKU'"
                    min-width="200"
                    >
                        <template slot-scope="scope">
                            {{ type == 2 ? scope.row.barcode : scope.row.sku }}
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Estado"
                    min-width="120"
                    >
                        <template slot-scope="scope">
                            {{ _.isNull(scope.row.estado) ? 'No registra' : parseInt(scope.row.estado) === 1 ? 'Activo' : 'Inactivo' }}
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Productos"
                    min-width="280"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.nombre }}
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Presentación"
                    min-width="150"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.presentacion }}
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Inventario actual"
                    min-width="200"
                    class-name="text-center"
                    >
                        <template slot-scope="scope">
                            {{ !_.isNull(scope.row.inventario_actual) ? agregarSeparadoresNumero(scope.row.inventario_actual) : '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Precio Actual"
                    min-width="200"
                    class-name="text-center"
                    >
                        <template slot-scope="scope">
                            <el-tooltip content="Delicado" placement="bottom" effect="light">
                                <i v-if="scope.row.valor == 0" class="icon-alert-circled f-19" style="color:#E50019" />
                            </el-tooltip>
                            {{ !_.isNull(scope.row.valor) ? separadorNumero(scope.row.valor) : '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Venta sin stock"
                    min-width="200"
                    class-name="text-center"
                    >
                        <template slot-scope="scope">
                            {{ _.isNull(scope.row.venta_sin_stock) ? '-' : scope.row.venta_sin_stock == 1 ? 'Si' : 'No' }}
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Compra mínima"
                    min-width="200"
                    class-name="text-center"
                    >
                        <template slot-scope="scope">
                            {{ _.isNull(scope.row.minima_compra_permitida) ? '-' : agregarSeparadoresNumero(scope.row.minima_compra_permitida) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Compra máxima"
                    min-width="200"
                    class-name="text-center"
                    >
                        <template slot-scope="scope">
                            {{ _.isNull(scope.row.maxima_compra_permitida) ? '-' : agregarSeparadoresNumero(scope.row.maxima_compra_permitida) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Inventario del proveedor"
                    min-width="220"
                    class-name="text-center"
                    >
                        <template slot-scope="scope">
                            {{ _.isNull(scope.row.inventario_proveedor) ? '-' : scope.row.inventario_proveedor === 1 ? 'Si' : 'No' }}
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Proveedor"
                    min-width="200"
                    >
                        <template slot-scope="scope">
                            {{ !_.isNull(scope.row.proveedor) ? scope.row.proveedor : '-' }}
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalConfirmarAccion" titulo="Confirmación" no-aceptar adicional="Confirmar" @adicional="toGoAction">
            <div class="row mx-0 justify-center">
                <div class="col-auto text-general f-14">
                    ¿Desea confirmar esta acción?
                </div>
            </div>
        </modal>
        <modal ref="modalCambiarProveedor" titulo="Cambiar Proveedor" no-aceptar adicional="Confirmar" @adicional="updateChange('id_proveedor', proveedor)">
            <div class="row mx-0 justify-center">
                <div class="col-10 px-0">
                    <p class="f-14 text-general mb-4 text-center">
                        Seleccione el proveedor de los productos seleccionador
                    </p>
                    <small class="pl-3 mt-3 text-general">
                        Proveedor
                    </small>
                    <el-select v-model="proveedor" class="w-100" size="small">
                        <el-option
                        v-for="item in proveedores"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
            </div>
        </modal>
        <modal ref="modalCambioCantidad" :titulo="action == 8 ? 'Cambiar compra mínima' : 'Cambiar compra máxima'" no-aceptar adicional="Confirmar" @adicional="updateChange(action == 8 ? 'minima_compra_permitida' : 'maxima_compra_permitida', compra)">
            <ValidationObserver ref="validacionCompra">
                <div class="row mx-0 justify-center">
                    <div class="col-10 px-0">
                        <p class="f-14 text-general mb-4 text-center">
                            Indique la compra {{ action == 8 ? 'mímina' : 'máxima' }} de los productos seleccionador
                        </p>
                        <small class="pl-3 mt-3 text-general">
                            Compra {{ action == 8 ? 'mímina' : 'máxima' }}
                        </small>
                        <ValidationProvider v-slot="{errors}" rules="required|numeric" :name="action == 8 ? 'Compra mímina' : 'Compra máxima'">
                            <el-input v-model="compra" placeholder="Cantidad" size="small" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
        <modal-importar-actualizar-datos ref="modalImportarActualizarDatos" @updateFile="toGoUpdateFile" />
    </section>
</template>

<script>
import Service from "~/services/productos/productosAdmin";

export default {
    components:{
        modalImportarActualizarDatos:() => import('~/pages/productos/partials/modalImportarActualizarDatos'),
    },
    data(){
        return {
            cargando: false,
            clear: true,
            fileName: this.$route.params.imported.fileName,
            type: this.$route.params.imported.type,
            tableData: this.$route.params.imported.data,
            existen: this.$route.params.imported.existen,
            noExisten: this.$route.params.imported.noExisten,

            productsSelect: [],
            proveedores: [],
            action: null,
            proveedor: null,
            compra: null,
            buscar: null,

            dataColumns: [
                { titulo: 'Barcode o  SKU', valor: 'barcode', ancho: '200' },
                { titulo: 'Estado', valor: 'estado' },
                { titulo: 'Productos', valor: 'productos', ancho: '200' },
                { titulo: 'Presentacion', valor: 'presentacion' },
                { titulo: 'Inventario actual', valor: 'inventario_actual', class: 'text-center', ancho: '200' },
                { titulo: 'Precio Actual', valor: 'precio_actual', class: 'text-center' },
                { titulo: 'Venta Sin Stock', valor: 'venta_sin_stock', class: 'text-center' },
                { titulo: 'Compra mínima', valor: 'compra_minima', class: 'text-center' },
                { titulo: 'Compra máxima', valor: 'compra_maxima', class: 'text-center' },
                { titulo: 'Inventario del proveedor', valor: 'inventario_proveedor', ancho: '250' },
                { titulo: 'Proveedor', valor: 'proveedor' },
            ]
        }
    },

    computed:{
        idCedis(){
            return Number(this.$route.params.id_cedis)
        },
        actions(){
            return [
                { id: 1, label: 'Activar productos' },
                { id: 2, label: 'Inactivar productos' },
                { id: 3, label: 'Vender sin stock' },
                { id: 4, label: 'Vender con stock' },
                { id: 5, label: 'Con inventario proveedor' },
                { id: 6, label: 'Sin inventario proveedor' },
                { id: 7, label: 'Cambiar proveedor' },
                { id: 8, label: 'Compra minima' },
                { id: 9, label: 'Compra maxima' },
            ]
        }
    },

    mounted(){
        this.getProveedores();
    },

    methods: {
        async getProveedores(){
            try {
                if(_.isNull(this.idCedis))return;

                const {data} = await Service.getProveedoresCedis(this.idCedis)
                this.proveedores = data.proveedores;
            } catch(e){
                this.error_catch(e)
            }
        },

        async validAction(){
            if(_.isNull(this.idCedis))return;

            if(this.productsSelect.length < 1){
                this.action = null;
                this.notificacion('Alerta', 'Debe seleccionar al menos un producto', 'warning');
                return;
            };

            this.confirmAction();
        },

        async confirmAction(){
            this.$refs.modalConfirmarAccion.toggle();
        },

        async toGoAction(){
            this.$refs.modalConfirmarAccion.toggle();

            switch(parseInt(this.action)){
            case 1:
                return this.updateChange('insert', null);
            case 2:
                return this.updateChange('estado', 0);
            case 3:
                return this.updateChange('venta_sin_stock', 1);
            case 4:
                return this.updateChange('venta_sin_stock', 0);
            case 5:
                return this.updateChange('inventario_proveedor', 1);
            case 6:
                return this.updateChange('inventario_proveedor', 0);
            case 7:
                this.proveedor = null;
                this.clear = false;
                this.$refs.modalCambiarProveedor.toggle();
                return;
            case 8:
            case 9:
                this.compra = null;
                this.$refs.validacionCompra.reset();
                this.$refs.modalCambioCantidad.toggle();
                return;
            }
        },

        async updateChange(column, value){
            try {
                const model = {
                    products: JSON.stringify(this.productsSelect),
                    column: column,
                    value: value,
                };
                const {data} = await Service.putUpdateChange(this.idCedis, model)

                if(data.exito){
                    let resultAction = this.actions.find((item) => item.id === parseInt(this.action)).label;
                    this.notificacion('Mensaje', `La acción ${resultAction} se realizo correctamente`, 'success');

                    this.updateData(data.products);

                    this.actionsWithModal();
                    this.action = null;
                }
            } catch(e){
                this.error_catch(e)
            }
        },

        async updateData(data){
            data.forEach(element => {
                let index = this.tableData.findIndex((item) => parseInt(item.id) === parseInt(element.id));
                this.tableData[index] = element;
            });
        },


        async selectedAll(event){
            let contador = this.tableData.length;

            if(event){
                this.productsSelect = [];
                for(let i = 0; i < contador; i++){
                    this.productsSelect.push(this.tableData[i].id);
                    this.tableData[i].active = true;
                }
            } else {
                this.productsSelect = [];
                for(let i = 0; i < contador; i++){
                    this.tableData[i].active = false;
                }
            }
        },

        async selectOnly(event, id){
            let index = this.tableData.findIndex((item) => parseInt(item.id) === parseInt(id));
            if(event){
                this.productsSelect.push(id);
            } else {
                if(index != -1){
                    this.productsSelect.splice(index, 1);
                }
            }
        },

        replaceFile(){
            this.$refs.modalImportarActualizarDatos.toggle();
        },

        toGoUpdateFile(data){
            this.cargando = true;

            this.tableData = [];
            this.productsSelect = [];
            this.action = null;
            this.proveedor = null;
            this.compra = null;
            this.buscar = null;

            this.fileName = data.imported.fileName,
            this.type = data.imported.type,
            this.tableData = data.imported.data,
            this.existen = data.imported.existen,
            this.noExisten = data.imported.noExisten,



            setTimeout(() => {
                this.cargando = false;
            },500);
        },

        actionsWithModal(){
            if(parseInt(this.action) == 7){
                return this.$refs.modalCambiarProveedor.toggle();
            }
            if(parseInt(this.action) == 8 || parseInt(this.action) == 9){
                return this.$refs.modalCambioCantidad.toggle();
            }
        },
    }
}
</script>
